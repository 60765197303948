import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { SettingsOutlined, ExtensionOffOutlined } from '@mui/icons-material';
import { ROUTES_PATH } from '../../constants';
import { useGetFnsViewsQuery } from '../../redux/services/fnsView/api';
import { useAppSelector } from '../../redux/store';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import Loader from '../Loader';

type EmptyMenuProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

const EmptyMenu = (props: EmptyMenuProps): JSX.Element => {
  const { anchorEl, handleClose } = props;

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        '& .MuiMenu-paper': {
          padding: '12px',
          maxHeight: 'calc(100% - 32px)',
        },
        '& ul': {
          display: 'flex',
          gap: '12px',
        },
      }}
    >
      <ExtensionOffOutlined />
      No FNS apps installed
    </Menu>
  );
};

const Icon = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: theme.shape.borderRadius,
  '&:not(.MuiAvatar-colorDefault)': {
    backgroundColor: '#fff',
  },
}));

const IconWrap = styled(ListItemAvatar)({
  minWidth: 36,
});

const SvgIconWrap = styled(Box)({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

interface ManageAppsItemProps {
  handleManageOpen: () => void;
}

const ManageAppsItem = ({ handleManageOpen }: ManageAppsItemProps) => (
  <>
    <Box sx={{ px: '6px', my: 1 }}>
      <Divider />
    </Box>
    <MenuItem
      onClick={handleManageOpen}
      id="MoreMenu-manage-app"
      sx={{ p: '6px' }}
    >
      <IconWrap>
        <SvgIconWrap>
          <SettingsOutlined />
        </SvgIconWrap>
      </IconWrap>
      <ListItemText primary="Manage apps" />
    </MenuItem>
  </>
);

const MoreDropdown = (props: Props) => {
  const { anchorEl, handleClose } = props;
  const isDemo = useAppSelector((state: IAppState) => state.isDemo);
  const history = useHistory();

  const { data, isLoading, refetch } = useGetFnsViewsQuery(undefined, {
    pollingInterval: anchorEl ? 5000 : 0,
  });

  const handleManageOpen = () => {
    history.push(ROUTES_PATH.MANAGE_APPS);
    handleClose();
  };

  useEffect(() => {
    if (!anchorEl) return;
    refetch();
  }, [anchorEl]);

  if (!data && !isDemo) {
    return <EmptyMenu anchorEl={anchorEl} handleClose={handleClose} />;
  }

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        '& .MuiMenu-paper': {
          maxHeight: 'calc(100% - 32px)',
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader nonetransform position="relative" x={0} y={0} size={25} />
        </Box>
      )}
      {data !== undefined
        ? data.map(({ view, link }) => (
            <MenuItem
              key={view.name}
              onClick={handleClose}
              component={Link}
              to={`/more/${view.name}`}
              sx={{ p: '6px' }}
              disabled={!link}
              // @ts-ignore
              id={`MoreMenu-${view.contextMap.fnsProjectId}`}
            >
              <IconWrap>
                <Icon src="/" alt={view.description} />
              </IconWrap>
              <ListItemText primary={view.description} />
            </MenuItem>
          ))
        : null}
      <ManageAppsItem handleManageOpen={handleManageOpen} />
    </Menu>
  );
};

export default MoreDropdown;
